import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga6Page from "~/components/Enga/Enga6Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Tous nos logements bénéficient d’une conception optimisée en matière de confort d’été pour limiter la sensation de chaleur.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - Mobilité</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga6Page />
    </>
  );
}
